import { Helmet } from 'react-helmet';

export default function PrivacyPolicy() {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://crispaholic.com/privacy-policy" />
                <title>Privacy Policy • Crispaholic</title>
                <meta name="description" content="Read the privacy policy for Crispaholic.com." />
            </Helmet>

            <div id="header-container">
                <header>
                    <h1>Privacy Policy</h1>
                    <p>Crispaholic.com (hereinafter referred to as "the Blog") is committed to protecting the privacy of its users. This Privacy Policy outlines the information we collect, how it is used, and the choices you have regarding your personal information.</p>
                </header>
            </div>

            <main>
                <section>
                    <div className="content">
                        <h2>Information We Collect:</h2>

                        {/*
                        <h3>Personal Information:</h3>
                        <ul>
                            <li>When you subscribe to our newsletter or create an account, we may collect your name and email address.</li>
                            <li>We may collect personal information submitted voluntarily through comments or other user-generated content.</li>
                        </ul>
                        */}

                        <h3>Automatically Collected Information:</h3>
                        <ul>
                            <li>We use cookies and similar technologies to collect non-personal information about your browsing behaviour on the Blog.</li>
                        </ul>

                        <h2>How We Use Your Information:</h2>

                        {/*
                        <h3>Personal Information:</h3>
                        <ul>
                            <li>We use your personal information to communicate with you, send newsletters, and respond to your inquiries.</li>
                            <li>We may use your personal information to improve our content and services.</li>
                        </ul>
                        */}

                        <h3>Automatically Collected Information:</h3>
                        <ul>
                            <li>We use non-personal information to analyse trends, administer the site, track user movements, and gather demographic information for aggregate use.</li>
                        </ul>

                        <h2>Sharing of Information:</h2>

                        <h3>Third-Party Service Providers:</h3>
                        <ul>
                            <li>We may share your personal information with trusted third-party service providers who assist us in operating our website and delivering services.</li>
                        </ul>

                        <h3>Legal Compliance:</h3>
                        <ul>
                            <li>We may disclose personal information when required by law or to protect our rights, privacy, safety, or property.</li>
                        </ul>

                        <h2>Your Choices:</h2>

                        {/*
                        <h3>Opt-Out:</h3>
                        <ul>
                            <li>You may opt-out of receiving newsletters or marketing communications by following the unsubscribe link provided in our emails.</li>
                        </ul>
                        */}

                        <h3>Cookies:</h3>
                        <ul>
                            <li>Most web browsers allow you to control cookies through settings. However, disabling cookies may limit your ability to use certain features of the Blog.</li>
                        </ul>

                        <h2>Security:</h2>
                        <ul>
                            <li>We implement reasonable security measures to protect your personal information. However, please be aware that no method of transmission over the internet or electronic storage is completely secure.</li>
                        </ul>

                        <h2>Children's Privacy:</h2>
                        <p>The Blog is not intended for children under 13 years of age. We do not knowingly collect or solicit personal information from children. If you believe that we have collected personal information from a child, please contact us immediately.</p>

                        <h2>Changes to this Privacy Policy:</h2>
                        <p>We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting on the Blog.</p>

                        <h2>Contact Information:</h2>
                        <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:hi@crispaholic.com">hi@crispaholic.com</a>.</p>

                        <p>By using Crispaholic.com, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our website.</p>
                    </div>
                </section>
            </main>
        </div>
    )
}