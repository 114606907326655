import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

export default function TermsAndConditions() {
    function handleInternalLink() {
        window.scrollTo(0, 0);
    }

    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://crispaholic.com/terms-and-conditions" />
                <title>Terms & Conditions • Crispaholic</title>
                <meta name="description" content="Read the terms and conditions for Crispaholic.com." />
            </Helmet>

            <div id="header-container">
                <header>
                    <h1>Terms & Conditions</h1>
                    <p>This is the terms and conditions for Crispaholic.com.</p>
                </header>
            </div>

            <main>
                <section>
                    <div className="content">
                        <h2>Acceptance of Terms</h2>
                        <p>By accessing or using Crispaholic.com (hereinafter referred to as "the Blog"), you agree to comply with and be bound by these terms and conditions. If you do not agree to these terms, please refrain from using the Blog.</p>

                        <h2>Intellectual Property</h2>
                        <p>All content on the Blog, including but not limited to text, images, graphics, logos, and videos, is the property of Crispaholic.com and is protected by copyright laws. You may not reproduce, distribute, or use any content from the Blog without the express written consent of Crispaholic.com.</p>

                        <h2>User Conduct</h2>
                        <p>You agree to use the Blog for lawful purposes only. You will not engage in any activity that could harm, disable, overburden, or impair the Blog or interfere with other users' enjoyment of the Blog. Additionally, you will not use the Blog to promote or engage in any illegal or unauthorised activities.</p>

                        {/*
                        <h2>Comments and User-Generated Content</h2>
                        <p>Crispaholic.com encourages user interaction through comments and contributions. However, we reserve the right to moderate and remove any content that is deemed inappropriate, offensive, or violates these terms and conditions.</p>
                        */}

                        <h2>Accuracy of Information</h2>
                        <p>The content provided on Crispaholic.com is for informational purposes only. While we strive to provide accurate and up-to-date information, we make no representations or warranties regarding the completeness, accuracy, or reliability of any content on the Blog.</p>

                        <h2>External Links</h2>
                        <p>The Blog may contain links to third-party websites for your convenience. Crispaholic.com does not endorse or assume responsibility for the content or availability of these external sites. Visiting these links is at your own risk.</p>

                        <h2>Privacy Policy</h2>
                        <p>Your privacy is important to us. Please review our <Link to="/privacy-policy" onClick={handleInternalLink}>Privacy Policy</Link>, which outlines how we collect, use, and disclose information from our users.</p>

                        <h2>Changes to Terms and Conditions</h2>
                        <p>Crispaholic.com reserves the right to update or modify these terms and conditions at any time without prior notice. Your continued use of the Blog after any changes constitutes acceptance of the updated terms.</p>

                        <h2>Disclaimer of Warranties</h2>
                        <p>The Blog is provided "as is" without any warranties, express or implied. Crispaholic.com disclaims all warranties, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>

                        <h2>Limitation of Liability</h2>
                        <p>Crispaholic.com shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use the Blog or any content therein.</p>

                        <p>By using Crispaholic.com, you acknowledge that you have read, understood, and agreed to these terms and conditions. If you have any questions or concerns, please contact us at <a href="mailto:hi@crispaholic.com">hi@crispaholic.com</a>.</p>
                    </div>
                </section>
            </main>
        </div>
    )
}