import { Link } from "react-router-dom";

export default function Logo({
    setIsNavVisible,
    setPageNumber,
    setDoesPageNumberExist
}) {
    function handleNav() {
        window.scrollTo(0, 0);
        setIsNavVisible(false);
        setPageNumber(1);
        setDoesPageNumberExist(true);
    }

    return (
        <Link to="/" id="logo" onClick={handleNav}>Crispaholic</Link>
    )
}