export function convertNameToSlug(name) {
    const nameCopy = name;
    return nameCopy.replaceAll("&", "and").replaceAll(" ", "-").toLowerCase();
}

export function convertSlugToName(slug) {
    const slugCopy = slug;
    if (slugCopy.includes("-")) {
        const slugAsArray = slugCopy.split("-");
        const capitalisedWords = slugAsArray.map((word) => {
            if (word === "and") {
                return "&";
            }
            else {
                return word[0].toUpperCase() + word.slice(1); 
            }
        })
        return capitalisedWords.join(" ");
    }
    else {
        return slugCopy[0].toUpperCase() + slugCopy.slice(1);
    }
}