import './App.css';
import { useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Logo from './components/Logo';
import Nav from './components/Nav';
import NavButton from './components/NavButton';
import Home from './pages/Home';
import Post from './pages/Post';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './components/Footer';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Error404 from './pages/Error404';

function App() {
    const [isNavVisible, setIsNavVisible] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [doesPageNumberExist, setDoesPageNumberExist] = useState(true);

    return (
        <div className="App">
            <div id="logo-and-nav-container">
                <div id="logo-and-nav">
                    <Logo
                        setIsNavVisible={setIsNavVisible}
                        setPageNumber={setPageNumber}
                        setDoesPageNumberExist={setDoesPageNumberExist}
                    />
                    <Nav
                        isNavVisible={isNavVisible}
                        setIsNavVisible={setIsNavVisible}
                    />
                </div>
            </div>

            <NavButton
                isNavVisible={isNavVisible}
                setIsNavVisible={setIsNavVisible}
            />

            <Routes>
                <Route path="/" element={
                    <Home
                        pageNumber={pageNumber}
                        setPageNumber={setPageNumber}
                        doesPageNumberExist={doesPageNumberExist}
                        setDoesPageNumberExist={setDoesPageNumberExist}
                    />
                }></Route>
                <Route path="/post/:post_slug" element={<Post />}></Route>
                <Route path="/about" element={<About />}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/terms-and-conditions" element={<TermsAndConditions />}></Route>
                <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
                <Route path="*" element={<Error404 />}></Route>
            </Routes>

            <Footer />  
        </div>
    );
}

export default App;