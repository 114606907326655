
import { Helmet } from 'react-helmet';
import { Link, useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import allPosts from '../assets/content/posts';
import * as utils from '../utils';
import PostCard from '../components/PostCard';

export default function Home({
    pageNumber,
    setPageNumber,
    doesPageNumberExist,
    setDoesPageNumberExist
}) {
    const [searchParams, setSearchParams] = useSearchParams();    
    const page = searchParams.get("page");
    const category = searchParams.get("category");
    const tag = searchParams.get("tag");

    const maxNumberOfPostsToDisplay = 30;
    const maxNumberOfPageNumbersToDisplay = 5;

    const [numberOfPages, setNumberOfPages] = useState(null);
    const [pages, setPages] = useState(null);
    const [posts, setPosts] = useState(null);

    const [categoryTitleAndH1, setCategoryTitleAndH1] = useState("");
    const [categoryDescription, setCategoryDescription] = useState("");

    useEffect(() => {
        if (page !== null) {
            setPageNumber(parseInt(page));
        }

        let postsToRender;
        if (!category && !tag) {
            postsToRender = [...allPosts];
        } else if (category) {
            postsToRender = allPosts.filter((post) => {
                return post.category.toLowerCase() === category.toLowerCase();
            });
            if (category === "news") {
                setCategoryTitleAndH1("Crisp News");
                setCategoryDescription("Crisp related news from around the world.");
            } else if (category === "reviews") {
                setCategoryTitleAndH1("Crisp Reviews");
                setCategoryDescription("My reviews of crisps from around the world.");
            } else if (category === "fun") {
                setCategoryTitleAndH1("Fun");
                setCategoryDescription("Fun and random posts about crisps.");
            } else if (category === "favourites") {
                setCategoryTitleAndH1("Favourites");
                setCategoryDescription("Lists of my favourites crisps.");
            } else if (category === "asmr") {
                setCategoryTitleAndH1("Crisps ASMR");
                setCategoryDescription("Listen to me eating crisps!");
            }
        } else if (tag) {
            postsToRender = allPosts.filter((post) => {
                return post.tags.some((postTag) => {
                    return postTag.toLowerCase() === utils.convertSlugToName(tag).toLowerCase();
                })
            })
        }

        const noOfPages = Math.ceil(postsToRender.length/maxNumberOfPostsToDisplay);
        setNumberOfPages(noOfPages);

        if (parseInt(page) > noOfPages) {
            setDoesPageNumberExist(false);
        }

        const pageNumbers = [];
        for (let pageNo = 1; pageNo <= noOfPages; pageNo++) {
            pageNumbers.push(pageNo);
        }

        let pageNumbersToDisplay;
        if (pageNumbers.length <= maxNumberOfPageNumbersToDisplay) {
            pageNumbersToDisplay = pageNumbers.slice(0, maxNumberOfPageNumbersToDisplay);
        } else {
            if (pageNumber <= Math.ceil(maxNumberOfPageNumbersToDisplay/2)) {
                pageNumbersToDisplay = pageNumbers.slice(0, maxNumberOfPageNumbersToDisplay);
            } else if (pageNumber >= noOfPages - Math.floor(maxNumberOfPageNumbersToDisplay/2)) {
                pageNumbersToDisplay = pageNumbers.slice(noOfPages - maxNumberOfPageNumbersToDisplay, noOfPages);
            } else {
                pageNumbersToDisplay = pageNumbers.slice(pageNumber - (Math.ceil(maxNumberOfPageNumbersToDisplay/2)), pageNumber + (maxNumberOfPageNumbersToDisplay/2));
            }
        }

        setPages(pageNumbersToDisplay);
        setPosts(postsToRender.slice((maxNumberOfPostsToDisplay * pageNumber) - maxNumberOfPostsToDisplay, maxNumberOfPostsToDisplay * pageNumber));
    }, [page, pageNumber, category, tag])

    function handlePageNumberLink(event) {
        window.scrollTo(0, 0);
        setPageNumber(parseInt(event.target.innerText));
    }

    function handlePaginationLink() {
        window.scrollTo(0, 0);
    }

    function handlePageErrorViewLastPage() {
        window.scrollTo(0, 0);
        setPageNumber(numberOfPages);
        setDoesPageNumberExist(true);
    }

    function handlePageErrorViewHomePage() {
        window.scrollTo(0, 0);
        setPageNumber(1);
        setDoesPageNumberExist(true);
    }

    if (!posts) {
        return null;
    }

    return (
        <div>
            {!category && !tag
                ? <Helmet>
                    <link rel="canonical" href="https://crispaholic.com/" />
                    <title>A Crisp Addict's Blog • Crispaholic</title>
                    <meta name="description" content="Writing about anything and everything crisp related." />
                </Helmet>
                : null
            }
            {category
                ? <Helmet>
                    <link rel="canonical" href={`https://crispaholic.com/category/${category}`} />
                    <title>{categoryTitleAndH1} • Crispaholic</title>
                    <meta name="description" content={categoryDescription} />
                </Helmet>
                : null
            }
            {tag
                ? <Helmet>
                    <link rel="canonical" href={`https://crispaholic.com/tag/${tag}`} />
                    <title>Tag: {utils.convertSlugToName(tag).toLowerCase()} • Crispaholic</title>
                    <meta name="description" content={`Posts with the tag '${utils.convertSlugToName(tag).toLowerCase()}'.`} />
                </Helmet>
                : null
            }
            
            <div id="header-container">
                {!category && !tag
                    ? <header>
                        <h1>A Crisp Addict's Blog</h1>
                        <p>Writing about anything and everything crisp related.</p>
                    </header>
                    : null
                }
                {category
                    ? <header>
                        <h1>{categoryTitleAndH1}</h1>
                        <p>{categoryDescription}</p>
                    </header>
                    : null
                }
                {tag
                    ? <header>
                        <h1>Tag: {utils.convertSlugToName(tag).toLowerCase()}</h1>
                        <p>Posts with the tag '{utils.convertSlugToName(tag).toLowerCase()}'.</p>
                    </header>
                    : null
                }
            </div>
            
            <main>
                <section>
                    <div className="post-cards">
                        {posts.map((post, index) => {
                            return (
                                <PostCard key={index} post={post} />
                            )
                        })}
                    </div>
                </section>

                <section>
                    {!category && !tag
                        ? doesPageNumberExist
                            ? <div id="pagination">
                                {pageNumber === 1
                                    ? null
                                    : <Link to="/?page=1" onClick={handlePaginationLink}>&lt;&lt;</Link>
                                }
                                {pageNumber === 1
                                    ? null
                                    : <Link to={`/?page=${pageNumber - 1}`} onClick={handlePaginationLink}>&lt;</Link>
                                }
                                {pages.map((pageNo, index) => {
                                    return pageNo === pageNumber
                                        ? <div key={index}><b>{pageNo}</b></div>
                                        : <Link to={`/?page=${pageNo}`} key={index} onClick={handlePageNumberLink}>{pageNo}</Link>
                                })}
                                {pageNumber === numberOfPages
                                    ? null
                                    : <Link to={`/?page=${pageNumber + 1}`} onClick={handlePaginationLink}>&gt;</Link>
                                }
                                {pageNumber === numberOfPages
                                    ? null
                                    : <Link to={`/?page=${numberOfPages}`} onClick={handlePaginationLink}>&gt;&gt;</Link>
                                }
                            </div>
                            : <div id="page-number-error-message">
                                <div className="error">Category, tag, or page number does not exist.</div>
                                <div>Visit the <Link to="/" onClick={handlePageErrorViewHomePage}>Home</Link> page.</div>
                            </div>
                        : null
                    }

                    {category
                        ? doesPageNumberExist
                            ? <div id="pagination">
                                {pageNumber === 1
                                    ? null
                                    : <Link to={`/?category=${category}&page=1`} onClick={handlePaginationLink}>&lt;&lt;</Link>
                                }
                                {pageNumber === 1
                                    ? null
                                    : <Link to={`/?category=${category}&page=${pageNumber - 1}`} onClick={handlePaginationLink}>&lt;</Link>
                                }
                                {pages.map((pageNo, index) => {
                                    return pageNo === pageNumber
                                        ? <div key={index}><b>{pageNo}</b></div>
                                        : <Link key={index} to={`/?category=${category}&page=${pageNo}`} onClick={handlePageNumberLink}>{pageNo}</Link>
                                })}
                                {pageNumber === numberOfPages
                                    ? null
                                    : <Link to={`/?category=${category}&page=${pageNumber + 1}`} onClick={handlePaginationLink}>&gt;</Link>
                                }
                                {pageNumber === numberOfPages
                                    ? null
                                    : <Link to={`/?category=${category}&page=${numberOfPages}`} onClick={handlePaginationLink}>&gt;&gt;</Link>
                                }
                            </div>
                            : <div id="page-number-error-message">
                                <div className="error">Category, tag, or page number does not exist.</div>
                                <div>Visit the <Link to="/" onClick={handlePageErrorViewHomePage}>Home</Link> page.</div>
                            </div>
                        : null
                    }

                    {tag
                        ? doesPageNumberExist
                            ? <div id="pagination">
                                {pageNumber === 1
                                    ? null
                                    : <Link to={`/?tag=${tag}&page=1`} onClick={handlePaginationLink}>&lt;&lt;</Link>
                                }
                                {pageNumber === 1
                                    ? null
                                    : <Link to={`/?tag=${tag}&page=${pageNumber - 1}`} onClick={handlePaginationLink}>&lt;</Link>
                                }
                                {pages.map((pageNo, index) => {
                                    return pageNo === pageNumber
                                        ? <div key={index}><b>{pageNo}</b></div>
                                        : <Link to={`/?tag=${tag}&page=${pageNo}`} key={index} onClick={handlePageNumberLink}>{pageNo}</Link>
                                })}
                                {pageNumber === numberOfPages
                                    ? null
                                    : <Link to={`/?tag=${tag}&page=${pageNumber + 1}`} onClick={handlePaginationLink}>&gt;</Link>
                                }
                                {pageNumber === numberOfPages
                                    ? null
                                    : <Link to={`/?tag=${tag}&page=${numberOfPages}`} onClick={handlePaginationLink}>&gt;&gt;</Link>
                                }
                            </div>
                            : <div id="page-number-error-message">
                                <div className="error">Category, tag, or page number does not exist.</div>
                                <div>Visit the <Link to="/" onClick={handlePageErrorViewHomePage}>Home</Link> page.</div>
                            </div>
                        : null
                    }
                </section>
            </main>
        </div>
    )
}