import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import allPosts from "../assets/content/posts";
import * as utils from '../utils';

export default function Error404() {
    const categories = [...new Set(allPosts.map((post) => {
        return post.category;
    }))];

    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://crispaholic.com/" />
                <title>404 • Crispaholic</title>
                <meta name="description" content="This page does not exist." />
            </Helmet>
            
            <div id="header-container">
                <header>
                    <h1>404</h1>
                </header>
            </div>

            <main>
                <section>
                    <div className="content">
                        <p>All the crisps you've eaten must have rotted your brain because the page you are looking for doesn't exist.</p>
                        <p>Check out the pages below:</p>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            {categories.map((category, index) => {
                                return <li key={index}><Link to={`/?category=${utils.convertNameToSlug(category)}&page=1`}>{category}</Link></li>
                            })}
                        </ul>
                    </div>
                </section>
            </main>
        </div>
    )
}