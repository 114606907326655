import { Helmet } from 'react-helmet';

export default function Contact() {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://crispaholic.com/contact" />
                <title>Contact • Crispaholic</title>
                <meta name="description" content="Get in touch with me." />
            </Helmet>

            <div id="header-container">
                <header>
                    <h1>Contact</h1>
                </header>
            </div>

            <main>
                <section>
                    <div className="content">
                        <p>If you want to let me know about crisps I should try, share crisp-related news, or have suggestions on how I could improve the site, you can email me at <a href="mailto:hi@crispaholic.com">hi@crispaholic.com</a>.</p>
                    </div>
                </section>
            </main>
        </div>
    )
}