import { Link } from "react-router-dom";
import allPosts from "../assets/content/posts";
import * as utils from '../utils';

export default function Nav({ isNavVisible, setIsNavVisible }) {
    const categories = [...new Set(allPosts.map((post) => {
        return post.category;
    }))];

    function handleNav() {
        window.scrollTo(0, 0);
        setIsNavVisible(false);
    }

    const styleNav = {
        left: isNavVisible ? "0%" : "100%"
    };

    return (
        <nav style={styleNav} onClick={handleNav}>
            <div id="nav-home"><Link to="/">Home</Link></div>
            {categories.map((category, index) => {
                return <div key={index}><Link to={`/?category=${utils.convertNameToSlug(category)}&page=1`}>{category}</Link></div>
            })}
            <div><Link to="/about">About</Link></div>
            <div><Link to="/contact">Contact</Link></div>
        </nav>
    )
}