import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import allPosts from '../assets/content/posts';
import * as utils from '../utils';
import PostCard from '../components/PostCard';

export default function Post() {
    const { post_slug } = useParams();

    const [post, setPost] = useState(null);
    const [relatedPosts, setRelatedPosts] = useState(null);
    const maxNumberOfRelatedPostsToDisplay = 30;

    useEffect(() => {
        const currentPost = allPosts.filter((singlePost) => singlePost.slug === post_slug);
        setPost(currentPost);        
        const similarPosts = allPosts.filter((singlePost) => {
            return (singlePost.slug !== post_slug && singlePost.tags.some((tag) => {
                return currentPost[0].tags.includes(tag);
            })) || (singlePost.slug !== post_slug && singlePost.category === currentPost[0].category);
        });
        setRelatedPosts(similarPosts.slice(0, maxNumberOfRelatedPostsToDisplay));
    }, [post_slug])

    let schemaMarkup;
    if (post) {
        schemaMarkup = {
            "@context": "https://schema.org",
            "@type": "BlogPosting",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": `https://crispaholic.com/post/${post[0].slug}`
            },
            "headline": post[0].name,
            "description": post[0].description,
            "image": {
                "@type": "ImageObject",
                "url": `https://crispaholic.com/images/${post[0].image.slug}`,
                "caption": post[0].image.caption,
                "description": post[0].image.alt
            },
            "datePublished": post[0].datePublished,
            "dateModified": post[0].dateModified,
            "author": {
                "@type": "Person",
                "name": post[0].author
            },
            "publisher": {
                "@type": "Organization",
                "name": "Crispaholic.com",
                // "logo": {
                //     "@type": "ImageObject",
                //     "url": "https://crispaholic.com/images/logo.webp",
                //     "width": 100,
                //     "height": 100
                // }
            },
            "articleBody": post[0].body,
            "keywords": post[0].tags
        };
    };    

    if (!post) {
        return null;
    }

    return (
        <div>
            <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>

            <Helmet>
                <link rel="canonical" href={`https://crispaholic.com/post/${post[0].slug}`} />
                <title>{post[0].name} • Crispaholic</title>
                <meta name="description" content={post[0].description} />
            </Helmet>

            <div id="header-container">
                <header>
                    <h1>{post[0].name}</h1>
                    <p>{post[0].description}</p>
                    <div><b>Published</b>: {new Date(post[0].datePublished).toDateString()}</div>
                    {post[0].dateModified
                        ? <div><b>Modified</b>: {new Date(post[0].dateModified).toDateString()}</div>
                        : null
                    }
                    <div>
                        <b>Category</b>: <Link to={`/?category=${utils.convertNameToSlug(post[0].category)}&page=1`} id="post-category">{post[0].category}</Link>
                    </div>
                    
                </header>
            </div>

            <main>
                <section>
                    <div dangerouslySetInnerHTML={{ __html: post[0].body }} className="content" />
                </section>
                
                <section>
                    <div id="post-tags">
                        {post[0].tags.map((tag, index) => {
                            return <Link key={index} to={`/?tag=${utils.convertNameToSlug(tag)}&page=1`}>{tag}</Link>
                        })}
                    </div>
                </section>

                <section id="related-posts">
                    <h3>Related Posts</h3>
                    {relatedPosts.length === 0
                        ? <div>No related posts.</div>
                        : <div className="post-cards">
                            {relatedPosts.map((post, index) => {
                                return (
                                    <PostCard key={index} post={post} />
                                )
                            })}
                        </div>
                    }
                </section>
            </main>
        </div>
    )
}