import { Helmet } from 'react-helmet';

export default function About() {
    return (
        <div>
            <Helmet>
                <link rel="canonical" href="https://crispaholic.com/about" />
                <title>About • Crispaholic</title>
                <meta name="description" content="Learn more about Crispaholic.com." />
            </Helmet>

            <div id="header-container">
                <header>
                    <h1>About</h1>
                </header>
            </div>

            <main>
                <section>
                    <div className="content">
                        <p>I’m Johnny from the UK and I started this blog as I’m addicted to crisps. I have at least a packet every day. I once ate 30 packets of crisps in three days.</p>
                        <p>I started this blog as I enjoy creating websites and wanted to build something around one of my passions.</p>
                        <p>My aim is to provide content that others will find entertaining or useful, like discovering and reviewing crisps from around the world, sharing crisp-related news, and providing fun and interesting facts.</p>
                        <p>Hope you enjoy the content I create and grow to love crisps as much as I do.</p>
                    </div>
                </section>
            </main>
        </div>
    )
}