import { Link } from "react-router-dom";

export default function PostCard({ post }) {
    function handlePostCard() {
        window.scrollTo(0, 0);
    }
    
    return (
        <div className="post-card">                                
            <Link to={`/post/${post.slug}`} onClick={handlePostCard}>
                <img src={`/images/${post.image.slug}`} alt={post.image.alt} loading="lazy"/>
            </Link>
            <div className="post-card-name-and-description">
                <Link to={`/post/${post.slug}`} onClick={handlePostCard}>
                    <div className="post-card-name">{post.name}</div>
                </Link>
                <div  className="post-card-description">{post.description}</div>
            </div>
        </div>
    )
}