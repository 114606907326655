export default function NavButton({ isNavVisible, setIsNavVisible }) {
    function handleNavButton() {
        setIsNavVisible((currentNavVisibility) => {
            return !currentNavVisibility;
        })
    }

    const styleNavButton = {
        transform: isNavVisible ? "rotate(45deg)" : "rotate(0deg)",
    };

    const styleNavButtonDiv ={
        background: isNavVisible ? "var(--rose)" : "var(--white)"
    }

    return (
        <div
            id="nav-button"
            onClick={handleNavButton}
            style={styleNavButton}
        >
            <div style={styleNavButtonDiv}></div>
            <div style={styleNavButtonDiv}></div>
        </div>
    )
}