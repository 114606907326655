import { Link } from "react-router-dom";
import allPosts from "../assets/content/posts";
import * as utils from '../utils';

export default function Footer() {
    const categories = [...new Set(allPosts.map((post) => {
        return post.category;
    }))];

    function handleFooterLinks() {
        window.scrollTo(0, 0);
    }

    return (
        <div id="footer-container">
            <footer>
                <div id="footer-sections">
                    <div className="footer-section">
                        <div className="footer-section-heading">Crispaholic.com</div>
                        <div onClick={handleFooterLinks} className="footer-section-links">
                            <Link to="/">Home</Link>
                            <Link to="/about">About</Link>
                            <Link to="/contact">Contact</Link>
                        </div>
                    </div>

                    <div className="footer-section">
                        <div className="footer-section-heading">Categories</div>
                        <div onClick={handleFooterLinks} className="footer-section-links">
                            {categories.map((category, index) => {
                                return <div key={index}><Link to={`/?category=${utils.convertNameToSlug(category)}&page=1`}>{category}</Link></div>
                            })}
                        </div>
                    </div>

                    {/* <div className="footer-section">
                        <div className="footer-section-heading">Socials</div>
                        <div className="footer-section-links">
                            <a href="https://twitter.com/CrispaholicCom" target="_blank" rel="noreferrer">Twitter</a>
                            <a href="https://www.instagram.com/crispaholiccom/" target="_blank" rel="noreferrer">Instagram</a>
                            <a href="https://www.pinterest.com/CrispaholicCom/" target="_blank" rel="noreferrer">Pinterest</a>
                            <a href="https://www.youtube.com/@Crispaholic" target="_blank" rel="noreferrer">YouTube</a>
                            <a href="https://www.twitch.tv/crispaholiccom" target="_blank" rel="noreferrer">Twitch</a>
                        </div>
                    </div> */}

                    <div className="footer-section">
                        <div className="footer-section-heading">Legal Stuff</div>
                        <div onClick={handleFooterLinks} className="footer-section-links">
                            <Link to="/terms-and-conditions">Terms & Conditions</Link>
                            <Link to="/privacy-policy">Privacy Policy</Link>
                        </div>
                    </div>
                </div>
                <div id="copyright">Copyright &copy; {new Date().getFullYear()} Crispaholic.com. All Rights Reserved.</div>
            </footer>
        </div>
    )
}